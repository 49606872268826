import * as React from "react"

import Layout from "@components/layout/layout"
import TextHero from "@components/textHero"
import Article from "@components/article"

const PrivacyPolicy = () => (
  <Layout title="Privacy policy">
    <TextHero title={`Privacy Policy`} />
    <Article
      content={`
      <h5>Last Updated: July 28, 2022</h5>

      <p>This Privacy Policy describes the privacy practices of Avalo ( “we”, “us”, or “our”) and how we handle personal information that we collect through our website — www.avalo.ai, through any other website that we own or control and which posts to this Privacy Policy, and through other interactions and correspondence with you (collectively, the “Service” or “Services”).</p>

      <p>We treat all personal information covered by this Privacy Policy as pertaining to individuals acting as business representatives, and not in their individual or household capacity.</p>

      <h3>Personal Information We Collect</h3>

      <p>Information you provide to us may include:</p>

      <ul>
        <li><b>Contact details</b>, such as your first and last name, email and mailing addresses, phone number, professional title and company name.</li>
        <li><b>Communications</b> that we exchange with you, including when you contact us with questions, feedback, or otherwise.</li>
        <li><b>Marketing data</b>, such as your preferences for receiving our marketing communications, and details about your engagement with them.</li>
        <li><b>Other data</b> not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.</li>
      </ul>

      <p><b>Information we obtain from third parties.</b> We may maintain pages on social media platforms, such as Twitter, LinkedIn, Facebook, and other third-party platforms. When you visit or interact with our pages on those platforms, the platform provider’s privacy policy will apply to your interactions and their collection, use and processing of your personal information. You or the platforms may provide us with information through the platform, and we will treat such information in accordance with this Privacy Policy. We may obtain your personal information from other third parties, such as publicly-available sources and data providers.</p>

      <p><b>Automatic data collection.</b> We and our service providers may automatically log information about you, your computer or mobile device, and your interaction over time with our website and the Services (in your capacity as our customer’s authorized user), our communications and other online services, such as:</p>

      <ul>
          <li><b>Device data</b>, such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 3G), and general location information such as city, state or geographic area.<li>
          <li><b>Online activity data</b>, such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the website, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access, and whether you have opened our marketing emails or clicked links within them.</li>
      </ul>

      <p><b>We use the following tools for automatic data collection:</b></p>

      <ul>
          <li>Cookies, which are text files that websites store on a visitor‘s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, and helping us understand user activity and patterns.</li>
          <li>Local storage technologies, like HTML5, that provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications.</li>
          <li>Web beacons, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked.</li>
      </ul>

      <h3>How We Use Personal Information</h3>

      <p>We use your personal information for the following purposes:</p>

      <p><b>To operate our Services:</b></p>

      <ul>
        <li>Provide, operate, maintain, secure and improve our Services.</li>
        <li>Provide information about our Services.</li>
        <li>Communicate with you about our Services, including by sending you announcements, updates, security alerts, and support and administrative messages.</li>
        <li>Understand your needs and interests, and personalize your experience with our Services and our communications.</li>
        <li>Respond to your requests, questions and feedback.</li>
      </ul>

      <p><b>Research and development.</b> As part of these activities, we may create aggregated, de-identified or other anonymous data from personal information we collect. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Services, and promote our business.</p>

      <p><b>Direct marketing.</b> We may send you direct marketing communications as permitted by law via postal mail, email, telephone, text message, and other means. You may opt out of our marketing communications as described in the “Opt out of marketing communications” section below.</p>

      <p><b>Compliance and protection</b>, including to:</p>

      <ul>
        <li>Comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.</li>
        <li>Protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims).</li>
        <li>Audit our internal processes for compliance with legal and contractual requirements and internal policies.</li>
        <li>Enforce the terms and conditions that govern our website and Services.</li>
        <li>Prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.</li>
      </ul>

      <h3>How We Share Personal Information</h3>
      <p>We may share your personal information with:</p>

      <p><b>Affiliates.</b> Our corporate parent, subsidiaries, and affiliates, for purposes consistent with this Privacy Policy.</p>

      <p><b>Service providers.</b> Companies and individuals that provide services on our behalf or help us operate our Services or our business (such as banking partners, hosting, information technology, customer support, email delivery, and website analytics services).</p>

      <p><b>Professional advisors.</b> Professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.</p>

      <p><b>Authorities and others.</b> Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the compliance and protection purposes described above.</p>

      <p><b>Business transferees.</b> Acquirers and other relevant participants in business transactions (or negotiations for such transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of, or equity interests in, Avalo (including, in connection with a bankruptcy or similar proceedings).</p>

      <h3>Your Choices</h3>

      <p><b>Opt out of marketing communications.</b> You may opt out of marketing-related communications by following the opt-out or unsubscribe instructions contained in the marketing communications we send you.</p>

      <p><b>Cookies.</b> Most browser settings let you delete and reject cookies placed by websites. Many browsers accept cookies by default until you change your settings. If you do not accept cookies, you may not be able to use all functionality of the Service and it may not work properly. For more information about cookies, including how to see what cookies have been set on your browser and how to manage and delete them, visit www.allaboutcookies.org.</p>

      <p><b>Do Not Track.</b> Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to "Do Not Track" or similar signals. To find out more about "Do Not Track," please visit http://www.allaboutdnt.com.</p>

      <h3>Other Sites and Services</h3>
      <p>Our Services may contain links to websites and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites or online services operated by third parties, and we are not responsible for their actions.</p>

      <h3>Data Security</h3>
      <p>We employ a number of technical, organizational and physical safeguards designed to protect the personal information we collect. However, no security measures are failsafe and we cannot guarantee the security of your personal information.</p>

      <h3>Children</h3>
      <p>The Services are not intended for use by children under 13 years of age. If we learn that we have collected personal information through our Services from a child under 13 without the consent of the child’s parent or guardian as required by law, we will delete it.</p>

      <h3>Job Applicants</h3>
      <p>When you visit the “Careers” portion of our website, we collect the information that you provide to us in connection with your job application. This includes business and personal contact information, professional credentials and skills, educational and work history, and other information of the type that may be included in a resume. This may also include diversity information that you voluntarily provide. We use this information to facilitate our recruitment activities and process employment applications, such as by evaluating a job candidate for an employment activity, and monitoring recruitment statistics. We may also use this information to provide improved administration of the website, and as otherwise necessary (a) to comply with relevant laws or to respond to subpoenas or warrants served on us; (b) to protect and defend the rights or property of us or others; (c) in connection with a legal investigation; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Service.</p>

      <h3>Changes to This Privacy Policy</h3>
      <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the website.</p>

      <h3>How to Contact Us</h3>
      <p>If you have any questions or comments regarding our privacy practices, you may contact us at <a href="mailto:info@avalo.ai">info@avalo.ai</a></p>
    `}
    />
  </Layout>
)

export default PrivacyPolicy
