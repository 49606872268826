import * as React from "react"

import Container from "@components/elements/container"
import Wysiwyg from "@components/elements/wysiwyg"

import styles from "./article.module.scss"

interface ArticleProps {
  content?: string
}

const Article = ({ content }: ArticleProps) => {
  if (!content) {
    return null
  }

  return (
    <section className={styles.article}>
      <Container className={styles.container}>
        <Wysiwyg className={styles.content} contentHtml={content} />
      </Container>
    </section>
  )
}

export default Article
